exports.components = {
  "component---src-pages-datenschutz-mdx": () => import("./../../../src/pages/datenschutz.mdx" /* webpackChunkName: "component---src-pages-datenschutz-mdx" */),
  "component---src-pages-en-data-protection-mdx": () => import("./../../../src/pages/en/data-protection.mdx" /* webpackChunkName: "component---src-pages-en-data-protection-mdx" */),
  "component---src-pages-en-imprint-mdx": () => import("./../../../src/pages/en/imprint.mdx" /* webpackChunkName: "component---src-pages-en-imprint-mdx" */),
  "component---src-pages-fr-mentions-legales-mdx": () => import("./../../../src/pages/fr/mentions-legales.mdx" /* webpackChunkName: "component---src-pages-fr-mentions-legales-mdx" */),
  "component---src-pages-fr-protection-des-donnees-mdx": () => import("./../../../src/pages/fr/protection-des-donnees.mdx" /* webpackChunkName: "component---src-pages-fr-protection-des-donnees-mdx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-category-default-mdx": () => import("./../../../src/templates/category.tsx?__contentFilePath=/home/gitlab-runner/builds/schneider/schneider-werbemittel/react-app/src/content/category-default.mdx" /* webpackChunkName: "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-category-default-mdx" */),
  "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-fineliner-index-mdx": () => import("./../../../src/templates/category.tsx?__contentFilePath=/home/gitlab-runner/builds/schneider/schneider-werbemittel/react-app/src/content/produkte/fineliner/index.mdx" /* webpackChunkName: "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-fineliner-index-mdx" */),
  "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-fueller-tintenroller-index-mdx": () => import("./../../../src/templates/category.tsx?__contentFilePath=/home/gitlab-runner/builds/schneider/schneider-werbemittel/react-app/src/content/produkte/fueller-tintenroller/index.mdx" /* webpackChunkName: "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-fueller-tintenroller-index-mdx" */),
  "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-kugelschreiber-index-mdx": () => import("./../../../src/templates/category.tsx?__contentFilePath=/home/gitlab-runner/builds/schneider/schneider-werbemittel/react-app/src/content/produkte/kugelschreiber/index.mdx" /* webpackChunkName: "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-kugelschreiber-index-mdx" */),
  "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-permanent-marker-index-mdx": () => import("./../../../src/templates/category.tsx?__contentFilePath=/home/gitlab-runner/builds/schneider/schneider-werbemittel/react-app/src/content/produkte/permanent-marker/index.mdx" /* webpackChunkName: "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-permanent-marker-index-mdx" */),
  "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-textmarker-index-mdx": () => import("./../../../src/templates/category.tsx?__contentFilePath=/home/gitlab-runner/builds/schneider/schneider-werbemittel/react-app/src/content/produkte/textmarker/index.mdx" /* webpackChunkName: "component---src-templates-category-tsx-content-file-path-home-gitlab-runner-builds-schneider-schneider-werbemittel-react-app-src-content-produkte-textmarker-index-mdx" */),
  "component---src-templates-dealer-search-tsx": () => import("./../../../src/templates/dealer-search.tsx" /* webpackChunkName: "component---src-templates-dealer-search-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-inquiry-success-tsx": () => import("./../../../src/templates/inquiry/success.tsx" /* webpackChunkName: "component---src-templates-inquiry-success-tsx" */),
  "component---src-templates-inquiry-tsx": () => import("./../../../src/templates/inquiry.tsx" /* webpackChunkName: "component---src-templates-inquiry-tsx" */),
  "component---src-templates-newsletter-response-tsx": () => import("./../../../src/templates/newsletter-response.tsx" /* webpackChunkName: "component---src-templates-newsletter-response-tsx" */),
  "component---src-templates-printing-techniques-tsx": () => import("./../../../src/templates/printing-techniques.tsx" /* webpackChunkName: "component---src-templates-printing-techniques-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-sustainability-subpage-tsx": () => import("./../../../src/templates/sustainability/subpage.tsx" /* webpackChunkName: "component---src-templates-sustainability-subpage-tsx" */),
  "component---src-templates-sustainability-tsx": () => import("./../../../src/templates/sustainability.tsx" /* webpackChunkName: "component---src-templates-sustainability-tsx" */)
}

