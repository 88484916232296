/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react";

import type { GatsbyBrowser } from "gatsby";

import { default as _get } from "lodash/get";

import "@schneider/design-system/dist/sds/sds.css";
import "@schneider/design-system/dist/sds/fonts/fonts.css";

import { SdsMDXProvider } from "./gatsby/mdx";
import Layout from "./src/templates/layout";
import { localeDefault, locales } from "./gatsby-config.i18n";

const scrollToHash = ({ hash, offset = 100 }) => {
  if (hash) {
    setTimeout(() => {
      window.scrollTo({
        top: document.querySelector(hash).offsetTop - offset,
        left: 0,
        behavior: "smooth",
      });
    }, 60);
  }
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  // @TODO share with gatsby-ssr
  const wrapInMain = _get(props, "pageContext.frontmatter.wrapInMain", false);
  const noindex = _get(props, "pageContext.frontmatter.noindex", false);

  const noindexElem = document.querySelector("meta[content='noindex']");
  if (noindex && !noindexElem) {
    // Add noindex tag if indexing is disabled, and there is no noindex tag
    let noindexTag = document.createElement("meta");
    noindexTag.setAttribute("name", "robots");
    noindexTag.setAttribute("content", "noindex");

    document.querySelector("head")?.appendChild(noindexTag);
  }

  if (noindexElem && !noindex) {
    // Remove noindex. If noindex tag was added, but current page should be indexable
    document.querySelector("head")?.removeChild(noindexElem);
  }

  return (
    <Layout wrapInMain={wrapInMain} {...props}>
      <SdsMDXProvider>{element}</SdsMDXProvider>
    </Layout>
  );
};

export const onRouteUpdate = ({ location }) => {
  // update html lang attribute on route change
  const localeFromPath = locales.find(
    (locale) =>
      location.pathname.includes(`/${locale}/`) ||
      location.pathname === `/${locale}`
  );
  const currentLocale = localeFromPath || localeDefault;
  if (document) {
    document.querySelector("html").setAttribute("lang", currentLocale);
  }

  scrollToHash({ hash: location.hash });
};
