import React from "react";
import { default as _get } from "lodash/get";
import { MDXProvider } from "@mdx-js/react";
import { calculateImageSizes } from "gatsby-plugin-image/dist/src/image-utils";

const components = {
  h1: (props) => (
    <h1
      className="sds-text sds-text--color-secondary sds-text--bold sds-text--size-l sds-text--bottom-s"
      {...props}
    />
  ),
  h2: (props) => (
    <h2
      className="sds-text sds-text--color-secondary sds-text--bold sds-text--size-m sds-text--bottom-s"
      {...props}
    />
  ),
  h3: (props) => (
    <h3
      className="sds-text sds-text--color-secondary sds-text--bold sds-text--size-s sds-text--bottom-s"
      {...props}
    />
  ),
  ul: (props) => (
    <ul
      className="sds-text sds-text--color-secondary sds-text--size-s sds-text--bottom-s"
      style={{ transform: "translateX(15px)", width: "calc(100% - 15px)" }}
      {...props}
    />
  ),
  p: (props) => (
    <p
      className="sds-text sds-text--color-secondary sds-text--size-s sds-text--bottom-s"
      {...props}
    />
  ),
  a: (props) => {
    const href = _get(props, "href");
    const isLinkExternal =
      href &&
      href.startsWith("http") &&
      !href.includes(
        process.env.SITE_URL.substring(0, process.env.SITE_URL.length - 1)
      );
    const extendedProps = {
      ...props,
      rel: isLinkExternal ? "noopener nofollow" : undefined,
      target: isLinkExternal ? "_blank" : undefined,
    };

    return <a className="sds-text__link" {...extendedProps} />;
  },
};

export const SdsMDXProvider = ({ children }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
);
