import { locales as localesImport } from "./locales/index.js";

/**
 * i18n: default locale & available locales
 */
export const localeDefault = "de";
export const locales = sortLocales(localesImport);

/**
 * based on the directories get the locale codes
 *
 * see @url https://dev.to/adrai/best-internationalization-for-gatsby-mkf
 */
function sortLocales(locales) {
  // localeDefault as first
  locales.splice(locales.indexOf(localeDefault), 1);
  locales.unshift(localeDefault);

  return locales;
}
