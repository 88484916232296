import React from "react";

import { container } from "./style.module.scss";

const PSI_NETWORK_URL = "https://psi-network.de";
const EUROPEAN_SOURCING_URL = "https://www.europeansourcing.com/";
const GWW_URL = "https://gww.de/";

const links = {
  psi: {
    de: `${PSI_NETWORK_URL}`,
    en: `${PSI_NETWORK_URL}/en/`,
    fr: `${PSI_NETWORK_URL}/en/`,
  },
  europeanSourcing: {
    de: `${EUROPEAN_SOURCING_URL}?lang=de`,
    en: `${EUROPEAN_SOURCING_URL}?lang=en`,
    fr: `${EUROPEAN_SOURCING_URL}?lang=fr`,
  },
  gww: {
    de: `${GWW_URL}`,
    en: `${GWW_URL}`,
    fr: `${GWW_URL}`,
  },
};

export default ({ locale = "de" }) => (
  <div className={container}>
    <a href={links.psi[locale]} target="_blank">
      <img src="/logos/PSI_weiss.svg" alt="" />
    </a>

    <a href={links.europeanSourcing[locale]} target="_lank">
      <img src="/logos/European-Sourcing_weiss.svg" alt="" />
    </a>

    <a href={links.gww[locale]} target="_blank">
      <img src="/logos/GWW_weiss.svg" alt="" />
    </a>
  </div>
);
