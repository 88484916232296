// TODO: move to partials
/**
 * React Dependencies
 */
import React, { useEffect, useState } from "react";

/**
 * Gatsby Dependencies
 */

import { Link, graphql, useStaticQuery } from "gatsby";

/**
 * NPM Dependencies
 */

import { default as _camelCase } from "lodash/camelCase";
import { default as _get } from "lodash/get";
import { default as _keyBy } from "lodash/keyBy";

/**
 * Schneider Design System Imports
 */

import {
  SdsCollapse,
  SdsFooter,
  SdsIcon,
  SdsMainNavigation,
  SdsRow,
} from "@schneider/design-system-react";

/**
 * Project Dependencies
 */

import { navIcon } from "./layout.module.scss";
import { localeDefault, locales } from "../../gatsby-config.i18n";
import LogoBarContainer from "@components/logo-bar-container";

// @TODO implement sitemap/pages tree for footer and navigation

// Move to globals with link to docs
declare global {
  var UC_UI: {
    showSecondLayer: Function;
  };
}

const Layout: React.FC = ({ children, wrapInMain, ...props }) => {
  const i18nContext = _get(props, "pageContext.i18n");
  const withLocaleTranslationFallback = (translations, locales) => {
    const available = translations.map((item) => item.locale);
    const neededFallbacks = locales
      .filter((fallbackCheck) => !available.includes(fallbackCheck))
      .map((fallback) => ({
        locale: fallback,
        // @TODO share with home url helper
        path: `/${fallback === localeDefault ? "" : fallback}`,
        slug: `/${fallback === localeDefault ? "" : fallback}`,
        order: locales.findIndex((item) => item === fallback),
      }));

    const availableAndFallbacks = [...translations, ...neededFallbacks];

    return availableAndFallbacks;
  };
  const translations = withLocaleTranslationFallback(
    _get(
      props,
      // default
      "pageContext.data.translations",
      _get(
        props,
        // context fallback
        "pageContext.i18nTranslations",
        // mdx i18n via frontmatter
        [
          // current translation
          // [
          //   _get(props, "pageContext.frontmatter.locale"),
          //   _get(props, "location.pathname"),
          // ],
          // linked translations
          ...Object.entries(_get(props, "pageContext.frontmatter.i18n", {})),
        ].map(([locale, path]) => {
          return {
            locale,
            path,
            slug:
              _get(props, "location.pathname", "").replace(`/${locale}`, "") ||
              `/${locale === localeDefault ? "" : locale}`,
            order: locales.findIndex((item) => item === locale),
          };
        })
      )
    ),
    locales
  ).sort((a, b) => a.order - b.order);

  const localeCode = _get(
    props,
    "pageContext.locale",
    _get(props, "pageContext.frontmatter.locale", localeDefault)
  );
  const data = useStaticQuery(graphql`
    query {
      termsAndConditionsHref: copybookXlsxGeneral(
        sid: { eq: "footer.termsAndConditionsHref" }
      ) {
        id
        de: DE
        en: EN
        fr: FR
      }
      locale: allCopybookXlsxGeneral(filter: { sid: { glob: "language.*" } }) {
        nodes {
          sid
          de: DE
          en: EN
          fr: FR
        }
      }
    }
  `);
  const i18nLocaleStrings = _keyBy(data.locale.nodes, "sid");
  const getIconWithLocale = (itemLocale) =>
    _camelCase(`country-${itemLocale === "en" ? "gb" : itemLocale}`);

  useEffect(() => {
    // update cookie layer language
    const changeUcUiLanguage = () => {
      if (
        typeof window !== "undefined" &&
        typeof window.UC_UI !== "undefined"
      ) {
        UC_UI.updateLanguage(localeCode);
      } else {
        setTimeout(changeUcUiLanguage, 50);
      }
    };
    changeUcUiLanguage();
  }, [localeCode]);

  const [hasNewsletterOnPage, setHasNewsletterOnPage] = useState(false);

  let url;
  if (typeof window !== "undefined") {
    url = window.location.pathname.split("/").pop();
  }

  const newsletterAnchor = {
    en: "subscribe-to-the-newsletter",
    de: "newsletter-abonnieren",
    fr: "sinscrire-a-la-newsletter",
  };

  useEffect(() => {
    setHasNewsletterOnPage(
      document.getElementById(newsletterAnchor[localeCode]) != null
    );
  }, []);

  useEffect(() => {
    setHasNewsletterOnPage(
      document.getElementById(newsletterAnchor[localeCode]) != null
    );
  }, [url]);

  // @TODO Copybook
  // @TODO hover
  const home = {
    de: "/",
    en: "/en",
    fr: "/fr",
  };
  const menuPrimary = {
    de: (
      <>
        <Link key="produkte" to="/produkte">
          Produkte
        </Link>
        <Link key="drucktechniken" to="/drucktechniken">
          Drucktechniken
        </Link>
        <Link key="service" to="/service">
          Service
        </Link>
        <Link key="nachhaltigkeit" to="/nachhaltigkeit">
          Nachhaltigkeit
        </Link>
        <a
          key="unternehmen"
          href="https://schneiderpen.com/de_de/unternehmen/wie-wir-denken/"
          target="_blank"
          rel="noopener"
        >
          Unternehmen{" "}
          <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
        </a>
        <a
          key="pen-confi"
          href="https://www.schneiderpen-configurator.com/de"
          target="_blank"
          rel="noopener"
        >
          Pen-Configurator{" "}
          <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
        </a>
      </>
    ),
    en: (
      <>
        <Link key="en/products" to="/en/products">
          Products
        </Link>
        <Link key="en/printing-techniques" to="/en/printing-techniques">
          Printing techniques
        </Link>
        <Link key="en/service" to="/en/service">
          Service
        </Link>
        <Link key="en/sustainability" to="/en/sustainability">
          Sustainability
        </Link>
        <a
          key="company"
          href="https://schneiderpen.com/en_uk/company/how-we-think/"
          target="_blank"
          rel="noopener"
        >
          Company <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
        </a>
        <a
          key="pen-confi-en"
          href="https://www.schneiderpen-configurator.com/en"
          target="_blank"
          rel="noopener"
        >
          Pen-Configurator{" "}
          <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
        </a>
      </>
    ),
    fr: (
      <>
        <Link key="fr/produits" to="/fr/produits">
          Produits
        </Link>
        <Link key="fr/techniques-de-marquage" to="/fr/techniques-de-marquage">
          Techniques de marquage
        </Link>
        <Link key="fr/service" to="/fr/service">
          Service
        </Link>
        <Link key="fr/durabilite" to="/fr/durabilite">
          Durabilité
        </Link>
        <a
          key="enterprise"
          href="https://schneiderpen.com/fr_fr/enterprise/comment-nous-pensons/"
          target="_blank"
          rel="noopener"
        >
          Enterprise{" "}
          <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
        </a>
        <a
          key="pen-confi-fr"
          href="https://www.schneiderpen-configurator.com/fr"
          target="_blank"
          rel="noopener"
        >
          Pen-Configurator{" "}
          <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
        </a>
      </>
    ),
  };
  const menuSecondary = {
    de: (
      <li>
        <Link
          to="/haendlersuche"
          key="/haendlersuche"
          className="sds-collapse__item sds-button sds-button--link"
        >
          Händlersuche
        </Link>
      </li>
    ),
    en: null,
    fr: null,
    // en: (
    //   <li>
    //     <Link
    //       to="/en/find-a-dealer"
    //       key="/en/find-a-dealer"
    //       className="sds-collapse__item sds-button sds-button--link"
    //     >
    //       Find a dealer
    //     </Link>
    //   </li>
    // ),
    // fr: (
    //   <li>
    //     <Link
    //       to="/fr/trouver-un-revendeur"
    //       key="/fr/trouver-un-revendeur"
    //       className="sds-collapse__item sds-button sds-button--link"
    //     >
    //       Revendeur
    //     </Link>
    //   </li>
    // ),
  };
  const menuFooter = {
    de: (
      <>
        <SdsCollapse heading="Produkte" key="Produkte">
          {/** TODO: use category structure */}
          <div slot="items">
            <Link
              to="/produkte/kugelschreiber"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Kugelschreiber
            </Link>
            <Link
              to="/produkte/textmarker"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Textmarker
            </Link>
            <Link
              to="/produkte/permanent-marker"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Permanent Marker
            </Link>
            <Link
              to="/produkte/fineliner"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Fineliner
            </Link>
            <Link
              to="/produkte/fueller-tintenroller"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Füller & Tintenroller
            </Link>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="Drucktechniken" key="Drucktechniken">
          <div slot="items">
            <Link
              to="/drucktechniken#siebdruck"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Siebdruck
            </Link>
            <Link
              to="/drucktechniken#tampondruck"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Tampondruck
            </Link>
            <Link
              to="/drucktechniken#digitaldruck"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Digitaldruck
            </Link>
            <Link
              to="/drucktechniken#folientransfer"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Folientransfer
            </Link>
            <Link
              to="/drucktechniken#gravur"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Gravur
            </Link>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="Service" key="Service">
          <div slot="items">
            <Link
              to="/service#downloads"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Downloads
            </Link>
            <Link
              to="/service#ihre-ansprechpartner"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Ansprechpartner
            </Link>
            <Link
              to="/haendlersuche"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Händlersuche
            </Link>
            <Link
              to="/service#newsletter-abonnieren"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Newsletter
            </Link>
            <a
              href="https://schneiderpen.com/de_de/kontakt/kontaktformular/"
              target="_blank"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Kontakt
            </a>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="Allgemein" key="Allgemein">
          <div slot="items">
            <Link
              to="/nachhaltigkeit"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Nachhaltigkeit
            </Link>
            <a
              href="https://schneiderpen.com/de_de/produkte/"
              target="_blank"
              rel="noopener"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Markenshop{" "}
              <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
            </a>
            <a
              href="https://schneiderpen.com/de_de/unternehmen/wie-wir-denken/"
              target="_blank"
              rel="noopener"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Unternehmen{" "}
              <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
            </a>
            <Link
              to="/impressum"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Impressum
            </Link>
            <Link
              to="/datenschutz"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Datenschutz
            </Link>
            <a
              onClick={(e) => {
                e.preventDefault();
                if (
                  typeof UC_UI !== "undefined" &&
                  typeof UC_UI.showSecondLayer === "function"
                ) {
                  UC_UI.showSecondLayer();
                } else {
                  console.warn("Usercentrics Layer not available");
                }
              }}
              href="#"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Cookie Einstellungen
            </a>
            <a
              href={_get(data, "termsAndConditionsHref", {})[localeCode]}
              target="_blank"
              className="sds-collapse__item sds-button sds-button--link"
            >
              AGB
            </a>
          </div>
        </SdsCollapse>
      </>
    ),
    en: (
      <>
        <SdsCollapse heading="Products" key="Products">
          {/** TODO: use category structure */}
          <div slot="items">
            <Link
              to="/en/products/ballpoint-pens"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Ballpoint Pens
            </Link>
            <Link
              to="/en/products/highlighters"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Highlighters
            </Link>
            <Link
              to="/en/products/permanent-markers"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Permanent Markers
            </Link>
            <Link
              to="/en/products/fineliners"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Fineliners
            </Link>
            <Link
              to="/en/products/fountain-pens-rollerballs"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Fountain Pens & Rollerballs
            </Link>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="Printing Techniques" key="Printing Techniques">
          <div slot="items">
            <Link
              to="/en/printing-techniques#screen-printing"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Screen printing
            </Link>
            <Link
              to="/en/printing-techniques#tampon-printing"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Tampon printing
            </Link>
            <Link
              to="/en/printing-techniques#digital-printing"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Digital printing
            </Link>
            <Link
              to="/en/printing-techniques#film-transfer-printing"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Film transfer printing
            </Link>
            <Link
              to="/en/printing-techniques#engraving"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Engraving
            </Link>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="Service" key="Service">
          <div slot="items">
            <Link
              to="/en/service#downloads"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Downloads
            </Link>
            <Link
              to="/en/service#your-contacts"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Your contacts
            </Link>
            {/* <Link
              to="/en/find-a-dealer"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Find a dealer
            </Link> */}
            <Link
              to="/en/service#subscribe-to-the-newsletter"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Newsletter
            </Link>
            <a
              href="https://schneiderpen.com/en_uk/contact/contact-form/"
              target="_blank"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Contact
            </a>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="General" key="General">
          <div slot="items">
            <Link
              to="/en/sustainability"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Sustainability
            </Link>
            {/* <a
              href="https://schneiderpen.com/de_de/produkte/"
              target="_blank"
              rel="noopener"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Markenshop{" "}
              <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
            </a> */}
            <a
              href="https://schneiderpen.com/en_uk/company/how-we-think/"
              target="_blank"
              rel="noopener"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Company{" "}
              <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
            </a>
            <Link
              to="/en/imprint"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Imprint
            </Link>
            <Link
              to="/en/data-protection"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Data Protection
            </Link>
            <a
              onClick={(e) => {
                e.preventDefault();
                if (
                  typeof UC_UI !== "undefined" &&
                  typeof UC_UI.showSecondLayer === "function"
                ) {
                  UC_UI.showSecondLayer();
                } else {
                  console.warn("Usercentrics Layer not available");
                }
              }}
              href="#"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Cookie Settings
            </a>
            <a
              href={_get(data, "termsAndConditionsHref", {})[localeCode]}
              target="_blank"
              className="sds-collapse__item sds-button sds-button--link"
            >
              TC
            </a>
          </div>
        </SdsCollapse>
      </>
    ),
    fr: (
      <>
        <SdsCollapse heading="Products" key="Products">
          {/** TODO: use category structure */}
          <div slot="items">
            <Link
              to="/fr/produits/stylos-a-bille"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Stylos à bille
            </Link>
            <Link
              to="/fr/produits/surligneurs"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Surligneurs
            </Link>
            <Link
              to="/fr/produits/marqueurs-permanents"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Marqueurs permanents
            </Link>
            <Link
              to="/fr/produits/fineliner"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Fineliner
            </Link>
            <Link
              to="/fr/produits/stylos-a-plume-et-rollers-a-encre"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Stylos à plume et rollers à encre
            </Link>
          </div>
        </SdsCollapse>
        <SdsCollapse
          heading="Techniques de marquage"
          key="Techniques de marquage"
        >
          <div slot="items">
            <Link
              to="/fr/techniques-de-marquage#serigraphie"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Sérigraphie
            </Link>
            <Link
              to="/fr/techniques-de-marquage#tampographie"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Tampographie
            </Link>
            <Link
              to="/fr/techniques-de-marquage#marquage-numerique"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Marquage numérique
            </Link>
            <Link
              to="/fr/techniques-de-marquage#impression-par-transfert-de-film"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Impression par transfert de film
            </Link>
            <Link
              to="/fr/techniques-de-marquage#gravure-laser"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Gravure laser
            </Link>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="Service" key="Service">
          <div slot="items">
            <Link
              to="/fr/service#downloads"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Téléchargements
            </Link>
            <Link
              to="/fr/service#vos-contacts"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Vos contacts
            </Link>
            {/* <Link
              to="/fr/trouver-un-revendeur"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Trouver un revendeur
            </Link> */}
            <Link
              to="/fr/service#sinscrire-a-la-newsletter"
              className="sds-collapse__item sds-button sds-button--link"
            >
              S'inscrire à la newsletter
            </Link>
            <a
              href="https://schneiderpen.com/fr_fr/contact/formulaire-de-contact/"
              target="_blank"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Contact
            </a>
          </div>
        </SdsCollapse>
        <SdsCollapse heading="General" key="General">
          <div slot="items">
            <Link
              to="/fr/durabilite"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Durabilité
            </Link>
            {/* <a
              href="https://schneiderpen.com/de_de/produkte/"
              target="_blank"
              rel="noopener"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Markenshop{" "}
              <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
            </a> */}
            <a
              href="https://schneiderpen.com/fr_fr/enterprise/comment-nous-pensons/"
              target="_blank"
              rel="noopener"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Enterprise{" "}
              <SdsIcon name="arrowTopRight" size="s" className={navIcon} />
            </a>
            <Link
              to="/fr/mentions-legales"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Mentions légales
            </Link>
            <Link
              to="/fr/protection-des-donnees"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Protection des données
            </Link>
            <a
              onClick={(e) => {
                e.preventDefault();
                if (
                  typeof UC_UI !== "undefined" &&
                  typeof UC_UI.showSecondLayer === "function"
                ) {
                  UC_UI.showSecondLayer();
                } else {
                  console.warn("Usercentrics Layer not available");
                }
              }}
              href="#"
              className="sds-collapse__item sds-button sds-button--link"
            >
              Paramètres des cookies
            </a>
            <a
              href={_get(data, "termsAndConditionsHref", {})[localeCode]}
              target="_blank"
              className="sds-collapse__item sds-button sds-button--link"
            >
              CGV
            </a>
          </div>
        </SdsCollapse>
      </>
    ),
  };

  const Content = () =>
    wrapInMain ? (
      <main style={{ minHeight: "60vh" }}>
        <SdsRow
          cols-xs="1"
          style={{
            "--sds-row__max-width": "1160px",
            "--sds-row__padding-x": "15px",
          }}
          margin="l"
        >
          {children}
        </SdsRow>
      </main>
    ) : (
      children
    );

  return (
    <React.Fragment key={_get(props, "path", undefined)}>
      <SdsMainNavigation metaInfo="Made in Germany">
        <span slot="logo">
          <Link to={home[localeCode]}>
            <picture>
              {/** TODO: use graphql for images */}
              <source
                media="(max-width: 1023px)"
                srcSet="/schneider-logo-small.png"
              />
              <img srcSet={`/schneider-logo-${localeCode}.png`} />
            </picture>
          </Link>
        </span>
        <div slot="primary">{menuPrimary[localeCode]}</div>
        <div slot="secondary">
          <ul className="menu">
            <li>
              <a>
                <SdsIcon
                  className="menu__icon"
                  size="s"
                  name={getIconWithLocale(localeCode)}
                  title={
                    _get(i18nLocaleStrings, "language.headline")[localeCode]
                  }
                />
              </a>
              <ul className="menu__dropdown">
                {translations.map((translation) => {
                  const itemLocale =
                    translation.locale === "en" ? "gb" : translation.locale;

                  const hasSearchString =
                    typeof window !== "undefined" &&
                    _get(window, "location.search", "").length > 0;

                  // Remove hreflang attribute if the are search strings
                  let hreflangVal = undefined;
                  if (!hasSearchString) {
                    hreflangVal = translation.locale;
                  }

                  return (
                    <li key={`${itemLocale}-li`}>
                      <Link
                        className={[
                          "sds-collapse__item sds-button sds-button--link",
                          translation.locale === localeCode
                            ? "menu__dropdown--active"
                            : null,
                        ]
                          .filter((item) => item)
                          .join(" ")}
                        key={translation.path}
                        to={translation.path}
                        hrefLang={hreflangVal}
                      >
                        <SdsIcon
                          className="menu__icon"
                          size="s"
                          name={getIconWithLocale(translation.locale)}
                        />
                        {
                          _get(i18nLocaleStrings, `language.${itemLocale}`)[
                            localeCode
                          ]
                        }
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>

            <li>
              <Link
                to={`${
                  !hasNewsletterOnPage
                    ? localeCode == "de"
                      ? "/"
                      : "/" + localeCode
                    : ""
                }#${newsletterAnchor[localeCode]}`}
                key={`#${newsletterAnchor[localeCode]}`}
                className="sds-collapse__item sds-button sds-button--link"
              >
                Newsletter
              </Link>
            </li>

            {menuSecondary[localeCode]}
          </ul>
        </div>
      </SdsMainNavigation>

      <Content />

      <LogoBarContainer locale={localeCode}></LogoBarContainer>
      <SdsFooter
        footerInfo={`©${new Date().getFullYear()} Schneider Schreibgerate GmbH`}
      >
        {menuFooter[localeCode]}
      </SdsFooter>
    </React.Fragment>
  );
};

export default Layout;

// export const query = graphql`
//   {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `;
